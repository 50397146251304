<template>
  <div>
    <v-dialog v-model="$store.state.actionDialog" width="1400" persistent>
      <v-card flat class="card">
        <v-toolbar outlined flat color="grey lighten-4">
          <v-toolbar-title> {{ $t("alfwrmh") }}</v-toolbar-title>
          <v-spacer />
          <v-btn @click="close()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form>
          <v-container fluid>
            <v-card outlined>
              <v-container fluid>
                <v-btn
                  @click="openAddDialog()"
                  large
                  color="primary"
                  class="btn"
                >
                  {{ $t("idhafh-hql") }}
                </v-btn>
                <v-btn
                  @click="$store.commit('setHistoryDialog')"
                  large
                  color="primary darken-3"
                  class="btn mx-2"
                >
                  {{ $t("idhafh-hql-mn-nwd-akhr") }}
                </v-btn>
                <v-divider class="mt-2" />
                <Loading v-if="$global.state.loading" />
                <v-data-table
                  v-if="!$global.state.loading"
                  class="mytable"
                  :items-per-page="$global.state.filter.pageSize"
                  :items="forms"
                  :loading="$global.state.loading"
                  :headers="headers"
                  hide-default-footer
                  :loading-text="$t('loading')"
                  :no-data-text="$t('no-data')"
                  :id="
                    $vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'
                  "
                >
                  <template v-slot:item.isRequired="{ item }">
                    <v-chip v-if="item.isRequired == true">{{
                      $t("ilzamy")
                    }}</v-chip>
                    <v-chip v-if="item.isRequired == false">{{
                      $t("ikhtyary")
                    }}</v-chip>
                  </template>

                  <template v-slot:item.actions="{ item, index }">
                    <v-tooltip bottom="">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          @click="deleteItem(item.id, index)"
                          v-on="on"
                          icon
                        >
                          <v-icon color="error"> delete </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("hthf") }}</span>
                    </v-tooltip>

                    <v-tooltip bottom="">
                      <template v-slot:activator="{ on }">
                        <v-btn @click="openEditDialog(item)" v-on="on" icon>
                          <v-icon color="info"> edit </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("edit") }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-container>
            </v-card>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="actionDialog" width="400">
      <v-card flat class="card">
        <v-toolbar outlined flat color="grey lighten-4">
          <v-toolbar-title>
            {{ actionDialog == "add" ? $t("idhafh") : $t("edit") }}
            {{ $t("hql") }}</v-toolbar-title
          >
          <v-spacer />
          <v-btn @click="closeActionDialog()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form @submit.prevent="submit()" ref="form">
          <v-container>
            <v-card outlined>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12">
                    <h3 class="mb-2">
                      {{ $t("akhtr-alhql") }}<span class="required">*</span>
                    </h3>
                    <v-autocomplete
                      clearable
                      v-model="item.fieldId"
                      item-text="name"
                      item-value="id"
                      :items="$global.state.fields"
                      class="mr-1"
                      :placeholder="$t('alhqwl')"
                      :loading="$global.state.loading"
                      :no-data-text="$t('no-data')"
                      :search-input.sync="searchField"
                      hide-details=""
                      outlined=""
                      filled=""
                    >
                      <template v-slot:prepend-item="">
                        <v-btn
                          @click="openAddFieldDialog()"
                          color="primary"
                          text
                        >
                          <v-icon>mdi-plus</v-icon>
                          {{ $t("adhafh-hql-jdyd") }}
                        </v-btn>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="12">
                    <h3 class="mb-2">
                      {{ $t("altslsl") }} <span class="required">*</span>
                    </h3>
                    <v-text-field
                      v-model="item.sequence"
                      :placeholder="$t('altslsl')"
                      filled
                      outlined
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col cols="12" md="12">
                    <h3 class="mb-2">
                      {{ $t("altqsym") }} <span class="required">*</span>
                    </h3>
                    <v-text-field
                      v-model="item.cols"
                      :placeholder="$t('altqsym')"
                      filled
                      outlined
                      hide-details="auto"
                    />
                  </v-col>

                  <v-col cols="12" md="12">
                    <!-- <h3 class="mb-2">نوع الإجراء <span class="required">*</span></h3> -->
                    <v-radio-group v-model="item.isRequired">
                      <v-radio :value="true" :label="$t('ilzamy')" />
                      <v-radio :value="false" :label="$t('ikhtyary')" />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-col cols="12" md="12">
              <v-row class="mt-2">
                <v-btn
                  type="submit"
                  :loading="$global.state.loading"
                  x-large
                  color="primary"
                  class="btn"
                  >{{ $t("save") }}</v-btn
                >
                <v-btn
                  @click="closeActionDialog()"
                  x-large
                  color="error"
                  class="mr-2 btn"
                  outlined
                  >{{ $t("cancel") }}</v-btn
                >
              </v-row>
            </v-col>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
    <history-dialog />
    <field-dialog />
  </div>
</template>

<script>
import historyDialog from "./historyDialog.vue";
import fieldDialog from "../../fields/dialog.vue";
export default {
  components: { historyDialog, fieldDialog },
  data() {
    return {
      dialogType: "add",
      formId: "",
      item: {},
      actionDialog: false,
      searchField: "",
      sequence: 0,
      headers: [
        {
          text: this.$t("altslsl"),
          value: "sequence",
        },
        {
          text: this.$t("altqsym"),
          value: "cols",
        },
        {
          text: this.$t("ism-alhql"),
          value: "field.name",
        },
        {
          text: this.$t("nwa-alhql"),
          value: "field.fieldType.name",
        },

        {
          text: this.$t("alqym-almdkhlh"),
          value: "isRequired",
        },
        {
          text: this.$t("alnwd-alhadhn-llhql"),
          value: "node.name",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      forms: [],
    };
  },
  mounted() {
    this.reset();
    this.$eventBus.$on("fill-fields", () => {
      this.forms = [];
      this.getItems();
      this.$global.dispatch(`getField`);
    });
    this.$eventBus.$on(`refresh`, () => {
      this.getItems();
      this.$global.dispatch(`getField`);
    });

    this.$global.dispatch(`getFieldType`);
  },
  methods: {
    reset() {
      this.item = {
        sequence: this.sequence,
        isRequired: false,
        cols: 12,
        fieldId: "",
        formNodes: [],
      };
    },
    async getItems() {
      this.$global.state.loading = true;
      try {
        const res = await this.$http.get(
          `Form?NodeId=${this.$store.state.itemDetails.id}&IsDeleted=false&PageIndex=1&PageSize=1000`
        );
        this.forms = res.data.result;
        // sort by sequence
        this.forms = this.forms.sort((a, b) => {
          return a.sequence - b.sequence;
        });
        let lastSequence = 1;
        if (this.forms.length > 0) {
          lastSequence = this.forms[this.forms.length - 1].sequence + 1;
        }
        this.sequence = lastSequence;
      } catch (err) {
        console.log(err);
        this.$service.faild(err.response.data.message);
      } finally {
        this.$global.state.loading = false;
      }
    },
    async deleteItem(id, i) {
      await this.$http.delete('/Form/' + id )
      this.$emit('delete');
      this.getItems();
    },
    submit() {
      this.item.formNodes.push({
        nodeId: this.$store.state.itemDetails.id,
      });
      if (this.dialogType == "add") {
        this.addItem();
      } else {
        this.editItem();
      }
    },
    async addItem() {
      this.$global.state.loading = true;
      try {
        await this.$http.post(`Form`, this.item);
        this.$service.success(this.$t("tm-alidhafh-bnjah"));
        await this.closeActionDialog();
        await this.getItems();
      } catch (err) {
        this.$service.faild(err.response.data.message);
      } finally {
        this.$global.state.loading = false;
      }
    },
    editItem() {
      this.$global.state.loading = true;
      this.$http
        .put(`Form/${this.formId}`, this.item)
        .then((res) => {
          this.closeActionDialog();
          this.$service.success(this.$t("tm-altadyl-bnjah"));
          this.getItems();
        })
        .catch((err) => {
          this.$service.faild(err.response.data.message);
        })
        .finally(() => (this.$global.state.loading = false));
    },
    close() {
      this.$global.state.forms = [];
      // this.$eventBus.$off(`fill-fields`);
      this.$store.commit("setActionsDialog");
      // this.reset()
      // this.resetValidation()
      this.$store.commit("setItemDetails", {});
    },
    closeActionDialog() {
      this.reset();
      // this.resetValidation();
      this.actionDialog = false;
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getSubNodes() {
      this.$global.dispatch(`getNodes`);
    },

    openEditDialog(item) {
      this.dialogType = "edit";
      this.formId = item.id;
      this.item.fieldId = item.field.id;
      this.item.isRequired = item.isRequired;
      this.item.cols = item.cols;
      this.item.sequence = item.sequence;
      this.actionDialog = true;
    },

    openAddDialog() {
      this.dialogType = "add";
      this.actionDialog = true;
    },
    openAddFieldDialog() {
      this.dialogType = "add";
      this.$store.state.addFieldDialog = true;
      this.$store.commit("setFieldDialog");
    },
  },
  watch: {
    searchField() {
      this.$global.dispatch(`getField`, { name: this.searchField });
    },
    actionDialog() {
      if (this.dialogType == "add") {
        this.reset();
      }
    },
  },
};
</script>
